import React from 'react';
import './separatortop.css';

export const SeparatorTopComponent = () => {
    return (
        <div className="separator-top">
            <img src="/images/footer_top.png" alt=""/>
        </div>
    )
}
