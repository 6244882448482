export const teaching_data = {
    'es' : [
            {
                    'name'          : 'Entornos de enseñanza y aprendizaje mediados por tecnologías',
                    'org'           : 'Oficina de Educación Online - Vicerrectoría de Tecnologías de la Información',
                    'tier'          : 'Curso Documento',
                    'year'          : '2021',
                    'description'   : 'Este curso gratuito está dirigido a la comunidad académica de la Universidad de Chile. Tiene como objetivo brindar orientaciones pedagógicas para facilitar el diseño, desarrollo y evaluación de programas semipresenciales y completamente en línea. El curso aborda conceptos clave y difunde buenas prácticas docentes en todas las etapas del desarrollo profesional y personal de los estudiantes.',
                    'participants'  : '126',
                    'course_id'     : 'course-v1:eol+EMT+2021_2',
                    'image'         : '/images/teaching/asset-v1_eol+EMT+2021_2+typeasset+blockabout_orientaciones.jpg',
            },
            {
                    'name'          : 'Enseñanza Mediada por Tecnologías con Perspectiva de Género',
                    'org'           : 'Oficina de Educación Online - Vicerrectoría de Tecnologías de la Información',
                    'tier'          : 'Micro Curso',
                    'year'          : '2023',
                    'description'   : 'Este curso en línea gratuito y abierto al público te enseña cómo enseñar usando tecnología con perspectiva de género. El curso cubre los conceptos básicos de género y equidad, y cómo aplicarlos en entornos de aprendizaje en línea. También analiza las políticas de género de la Universidad de Chile.',
                    'participants'  : '37',
                    'course_id'     : 'course-v1:vlabx+C9+2022_2',
                    'image'         : '/images/teaching/asset-v1_vlabx+C9+2022_2+typeasset+blockportada_2022_09.jpg',
            },
            {
                    'name'          : 'Entornos de Enseñanza y Aprendizaje Mediados por Tecnologías',
                    'org'           : 'Oficina de Educación Online - Vicerrectoría de Tecnologías de la Información',
                    'tier'          : 'Micro Curso',
                    'year'          : '2023',
                    'description'   : 'Este es un artículo sobre un curso gratuito en línea llamado Entornos de Enseñanza y Aprendizaje Mediados por Tecnologías. El curso está dirigido a profesores de la Universidad de Chile que quieren aprender a usar la tecnología en la enseñanza. El curso cubre los conceptos clave y las mejores prácticas para el diseño, desarrollo y evaluación de programas en línea y semipresenciales. El curso también responde preguntas sobre la educación mediada por tecnología.',
                    'participants'  : '41',
                    'course_id'     : 'course-v1:vlabx+C2+2022_2',
                    'image'         : '/images/teaching/asset-v1_vlabx+C2+2022_2+typeasset+blockportada_2022_02.jpg',
            },
            {
                    'name'          : 'Certificación del Programa de Formación para Docentes',
                    'org'           : 'Oficina de Educación Online - Vicerrectoría de Tecnologías de la Información',
                    'tier'          : 'Curso Documento',
                    'year'          : '2023',
                    'description'   : 'Este programa de cursos está dirigido a Académicos/as y Docentes de la Escuela de Postgrado y Educación Continua de la Facultad de Ciencias Físicas y Matemáticas que actualmente dictan o desean dictar programas en modalidad virtual. Esta iniciativa obedece a las políticas institucionales para impulsar acciones de apoyo en materia de aseguramiento de la calidad para la virtualización de los programas.',
                    'participants'  : '28',
                    'course_id'     : 'course-v1:vlabx+CF+2023_1',
                    'image'         : '/images/teaching/asset-v1_vlabx+CF+2023_1+typeasset+blockportada_2022_10__2_.jpg',
            },
            {
                    'name'          : 'Conociendo EOL',
                    'org'           : 'Oficina de Educación Online - Vicerrectoría de Tecnologías de la Información',
                    'tier'          : 'Curso Documento',
                    'year'          : '2022',
                    'description'   : 'Este curso gratuito está dirigido a académicos de la Universidad de Chile que deseen aprender a utilizar la tecnología en la docencia. No se requieren conocimientos previos de informática, ya que el objetivo es formar usuarios informados y competentes.',
                    'participants'  : '109',
                    'course_id'     : 'course-v1:eol+C1+2022_1',
                    'image'         : '/images/teaching/asset-v1_eol+C1+2022_1+typeasset+blockportada_2022_01.jpg',
            },
            {
                    'name'          : 'Entornos de Enseñanza y Aprendizaje Mediados por Tecnologías',
                    'org'           : 'Oficina de Educación Online - Vicerrectoría de Tecnologías de la Información',
                    'tier'          : 'Malla EOL',
                    'year'          : '2022',
                    'description'   : 'El curso está dirigido a profesores de la Facultad de Ciencias Físicas y Matemáticas que desean dictar programas en línea. El objetivo del curso es enseñar a los profesores cómo usar la tecnología en la enseñanza. El curso cubre temas como la educación mediada por tecnología, el diseño de cursos en línea y la evaluación de programas en línea. El curso es gratuito y no requiere requisitos previos.',
                    'participants'  : '104',
                    'course_id'     : 'course-v1:vlabx+C2+2022_2',
                    'image'         : '/images/teaching/asset-v1_vlabx+C2+2022_2+typeasset+blockportada_2022_02.jpg',
            },
            {
                    'name'          : 'Enseñanza Mediada por Tecnologías con Perspectiva de Género',
                    'org'           : 'Oficina de Educación Online - Vicerrectoría de Tecnologías de la Información',
                    'tier'          : 'Malla EOL',
                    'year'          : '2022',
                    'description'   : 'Este curso está dirigido a académicos y docentes de la Facultad de Ciencias Físicas y Matemáticas que dictan o desean dictar programas virtuales. El curso tiene como objetivo que los participantes conozcan las políticas institucionales de género e igualdad, y aprendan a aplicarlas en entornos mediados por tecnologías.',
                    'participants'  : '81',
                    'course_id'     : 'course-v1:vlabx+C9+2022_2',
                    'image'         : '/images/teaching/asset-v1_vlabx+C9+2022_2+typeasset+blockportada_2022_09.jpg',
            },
            {
                    'name'          : 'Herramientas tecnológicas para la evaluación',
                    'org'           : 'Oficina de Educación Online - Vicerrectoría de Tecnologías de la Información',
                    'tier'          : 'Malla EOL',
                    'year'          : '2022',
                    'description'   : 'Este curso está diseñado para académicos y docentes que imparten o desean impartir programas virtuales en la Facultad de Ciencias Físicas y Matemáticas. El objetivo principal es brindarles las herramientas necesarias para comprender y utilizar la tecnología de manera efectiva en la evaluación de entornos virtuales de aprendizaje.',
                    'participants'  : '74',
                    'course_id'     : 'course-v1:vlabx+C6+2022_2',
                    'image'         : '/images/teaching/asset-v1_vlabx+C6+2022_2+typeasset+blockportada_2022_06.jpg',
            },
            {
                    'name'          : 'Metodologías Activas de Enseñanzas',
                    'org'           : 'Oficina de Educación Online - Vicerrectoría de Tecnologías de la Información',
                    'tier'          : 'Malla EOL',
                    'year'          : '2022',
                    'description'   : 'El curso está dirigido a profesores de la Facultad de Ciencias Físicas y Matemáticas que enseñan o quieren enseñar en línea. El curso ayuda a los profesores a aprender a usar la tecnología en la enseñanza y cubre temas como clases remotas, clases híbridas y aprendizaje basado en problemas. El curso es gratuito y se realiza a su propio ritmo.',
                    'participants'  : '68',
                    'course_id'     : 'course-v1:vlabx+C3+2022_2',
                    'image'         : '/images/teaching/asset-v1_vlabx+C3+2022_2+typeasset+blockportada_2022_03.jpg',
            },
            {
                    'name'          : 'Planificación y cubicación de un curso',
                    'org'           : 'Oficina de Educación Online - Vicerrectoría de Tecnologías de la Información',
                    'tier'          : 'Malla EOL',
                    'year'          : '2022',
                    'description'   : 'El curso está dirigido a profesores de la Facultad de Ciencias Físicas y Matemáticas que quieren enseñar en línea. El curso les enseñará a usar la tecnología para enseñar y cómo planificar sus cursos. El curso es gratuito y se realiza en línea.',
                    'participants'  : '64',
                    'course_id'     : 'course-v1:vlabx+C4+2022_2',
                    'image'         : '/images/teaching/asset-v1_vlabx+C4+2022_2+typeasset+blockportada_2022_04.jpg',
            },
            {
                    'name'          : 'La Producción de material didáctico para entornos virtuales de aprendizaje',
                    'org'           : 'Oficina de Educación Online - Vicerrectoría de Tecnologías de la Información',
                    'tier'          : 'Malla EOL',
                    'year'          : '2023',
                    'description'   : 'Este programa de cursos está dirigido a Académicos/as y Docentes de la Escuela de Postgrado y Educación Continua de la Facultad de Ciencias Físicas y Matemáticas que actualmente dictan o desean dictar programas en modalidad virtual. Esta iniciativa obedece a las políticas institucionales para impulsar acciones de apoyo en materia de aseguramiento de la calidad para la virtualización de los programas.',
                    'participants'  : '57',
                    'course_id'     : 'course-v1:vlabx+C5+2022_2',
                    'image'         : '/images/teaching/asset-v1_vlabx+C5+2022_2+typeasset+blockportada_2022_05.jpg',
            },
            {
                    'name'          : 'Movilidad virtual/hyflex',
                    'org'           : 'Oficina de Educación Online - Vicerrectoría de Tecnologías de la Información',
                    'tier'          : 'Malla EOL',
                    'year'          : '2023',
                    'description'   : 'Este programa de cursos está dirigido a Académicos/as y Docentes de la Escuela de Postgrado y Educación Continua de la Facultad de Ciencias Físicas y Matemáticas que actualmente dictan o desean dictar programas en modalidad virtual. Esta iniciativa obedece a las políticas institucionales para impulsar acciones de apoyo en materia de aseguramiento de la calidad para la virtualización de los programas.',
                    'participants'  : '55',
                    'course_id'     : 'course-v1:vlabx+C10+2022_2',
                    'image'         : '/images/teaching/asset-v1_vlabx+C10+2022_2+typeasset+blockHyflex.jpg',
            },
            {
                    'name'          : 'Analítica y evaluación de la implementación de un curso mediado por tecnología',
                    'org'           : 'Oficina de Educación Online - Vicerrectoría de Tecnologías de la Información',
                    'tier'          : 'Malla EOL',
                    'year'          : '2023',
                    'description': 'Este programa de cursos está dirigido a Académicos/as y Docentes de la Escuela de Postgrado y Educación Continua de la Facultad de Ciencias Físicas y Matemáticas que actualmente dictan o desean dictar programas en modalidad virtual. Esta iniciativa obedece a las políticas institucionales para impulsar acciones de apoyo en materia de aseguramiento de la calidad para la virtualización de los programas.',
                    'participants'  : '53',
                    'course_id'     : 'course-v1:vlabx+C7+2022_2',
                    'image'         : '/images/teaching/asset-v1_vlabx+C7+2022_2+typeasset+blockportada_2022_07.jpg',
            },
            {
                    'name'          : 'Docencia Mediada por Tecnologías: el siguiente desafío',
                    'org'           : 'Oficina de Educación Online - Vicerrectoría de Tecnologías de la Información',
                    'tier'          : 'Curso Documento',
                    'year'          : '2023',
                    'description'   : 'El uso de las tecnologías en nuestro quehacer diario ha adquirido una importancia indiscutible e innegable, por lo que integrarlo a la práctica docente se hace cada vez más coherente y relevante. Este curso busca ser una primera instancia de aproximación a los marcos teóricos y conceptuales que dan soporte a la incorporación progresiva de herramientas tecnológicas en el proceso de enseñanza-aprendizaje, así como de reflexión sobre las propias prácticas, en colaboración con pares, para diseñar propuestas docentes que incorporen esta mediación tecnológica, de manera situada y pertinente para cada contexto disciplinar y personal.',
                    'participants'  : '33',
                    'course_id'     : 'course-v1:eol+C12+2023_2',
                    'image'         : 'images/teaching/asset-v1_eol+C12+2023_2+typeasset+blockabout_Docencia.jpg',
            },
            {
                    'name'          : 'Herramientas de comunicación e interacción con el estudiante online',
                    'org'           : 'Oficina de Educación Online - Vicerrectoría de Tecnologías de la Información',
                    'tier'          : 'Curso Documento',
                    'year'          : '2020',
                    'description'   : 'El curso “Herramientas de comunicación e interacción con el estudiante online” tiene como meta que los participantes logren emplear herramientas de comunicación e interacción con sus estudiantes en ambientes virtuales de aprendizaje. Es un curso breve de 13 horas de duración, de enfoque teórico- práctico, que entregará el contexto y sentido de la interacción y comunicación en la educación en línea y, por otra parte, aportará con orientaciones y herramientas para favorecer dicha interacción.',
                    'participants'  : '225',
                    'course_id'     : 'course-v1:eol+EOL-100+2020_1',
                    'image'         : '/images/teaching/asset-v1_eol+EOL-100+2020_1+typeasset+blockcurso1_2.jpg',
            },
            {
                    'name'          : 'Herramientas para la evaluación en entornos virtuales de aprendizaje',
                    'org'           : 'Oficina de Educación Online - Vicerrectoría de Tecnologías de la Información',
                    'tier'          : 'Curso Documento',
                    'year'          : '2020',
                    'description'   : 'El curso “Herramientas para la evaluación en entornos virtuales de aprendizaje” tiene como meta que las y los participantes logren elaborar herramientas de evaluación en entornos virtuales de aprendizaje.',
                    'participants'  : '206',
                    'course_id'     : 'course-v1:eol+EOL-200+2020_2',
                    'image'         : '/images/teaching/asset-v1_eol+EOL-200+2020_2+typeasset+blockcurso2.jpg',
            },
            {
                    'name'          : 'IA en Educación Superior: Desafíos y Transformaciones',
                    'org'           : 'Oficina de Educación Online - Vicerrectoría de Tecnologías de la Información',
                    'tier'          : 'Curso Documento',
                    'year'          : '2023',
                    'description'   : 'En este curso los participantes descubrirán los desafíos que representa la Inteligencia Artificial (IA) para la educación superior, riesgos, oportunidades y transformaciones para la práctica docente. En particular, herramientas como ChatGPT, pero también otras que han revolucionado la industria.',
                    'participants'  : '102',
                    'course_id'     : 'course-v1:eol+C11+2023_1',
                    'image'         : '/images/teaching/asset-v1_eol+C11+2023_1+typeasset+blockEOL_cursoAI_01.jpg',
            }
    ]
}
